@font-face {
  font-family: "InterMedium";
  src: url("../../public/fonts/Inter-Medium.ttf");

  /* Add more src lines if you have multiple font file formats */
}

@font-face {
  font-family: "InterSemiBold";
  src: url("../../public/fonts/Inter-SemiBold.ttf");

  /* Add more src lines if you have multiple font file formats */
}

@font-face {
  font-family: "InterMedium";
  src: url("../../public/fonts/Inter-Medium.ttf");

  /* Add more src lines if you have multiple font file formats */
}
@font-face {
  font-family: "InterLight";
  src: url("../../public/fonts/Inter-Light.ttf");

  /* Add more src lines if you have multiple font file formats */
}

:root {
  /*--------------- font_size ---------------*/
  --fs_xsm: 15px;
  --fs_sm: 16px;
  --fs_md: 20px;
  --fs_lg: 24px;
  --fs_xl: 30px;
  --fs_xxl: 32px;
  --fs_xxxl: 35px;
}
.fs_xsm {
  font-size: var(--fs_xsm);
}

.fs_sm {
  font-size: var(--fs_sm);
}

.fs_md {
  font-size: var(--fs_md);
}

.fs_lg {
  font-size: var(--fs_lg);
}

.fs_xl {
  font-size: var(--fs_xl);
}

.fs_xxl {
  font-size: var(--fs_xxl);
}

.fs_xxxl {
  font-size: var(--fs_xxl);
}

/*--------------- font_weight ---------------*/

/*--------------- font_family ---------------*/

.ff_primary {
  font-family: "InterMedium";
}
.fw_medium {
  font-weight: 500;
}
.fw_semibold {
  font-weight: 600;
}
@media (max-width: 576px) {
  :root {
    /*--------------- font_size ---------------*/
    --fs_xsm: 15px;
    --fs_sm: 16px !important;
    --fs_md: 20px;
    --fs_lg: 14px;
    --fs_xl: 15px;
    --fs_xxl: 18px;
    --fs_xxxl: 35px;
  }
}
@media (min-width: 576px) {
  :root {
    /*--------------- font_size ---------------*/
    --fs_xsm: 15px;
    --fs_sm: 16px !important;
    --fs_md: 20px;
    --fs_lg: 14px;
    --fs_xl: 15px;
    --fs_xxl: 18px;
    --fs_xxxl: 35px;
  }
}
@media (min-width: 768px) {
  :root {
    /*--------------- font_size ---------------*/
    --fs_xsm: 15px;
    --fs_sm: 16px !important;
    --fs_md: 20px;
    --fs_lg: 14px;
    --fs_xl: 15px;
    --fs_xxl: 18px;
    --fs_xxxl: 35px;
  }
}
@media (min-width: 992px) {
  :root {
    /*--------------- font_size ---------------*/
    --fs_xsm: 15px;
    --fs_sm: 16px !important;
    --fs_md: 20px;
    --fs_lg: 24px;
    --fs_xl: 20px;
    --fs_xxl: 20px;
    --fs_xxxl: 35px;
  }
}
@media (min-width: 1200px) {
  :root {
    /*--------------- font_size ---------------*/
    --fs_xsm: 15px;
    --fs_sm: 16px !important;
    --fs_md: 20px;
    --fs_lg: 24px;
    --fs_xl: 30px;
    --fs_xxl: 28px;
    --fs_xxxl: 35px;
  }
}
@media (min-width: 1400px) {
  :root {
    /*--------------- font_size ---------------*/
    --fs_xsm: 15px;
    --fs_sm: 16px !important;
    --fs_md: 20px;
    --fs_lg: 24px;
    --fs_xl: 30px;
    --fs_xxl: 32px;
    --fs_xxxl: 35px;
  }
}

.button {
  margin-right: 1px;
  margin-left: 1px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 12px;
}

@media (max-width: 600px) {
  .button {
    font-size: 12px; /* Adjust the fontsize for small screens */
  }
}
