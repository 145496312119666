@media only screen and (max-width: 767.95px) {
  .left {
    display: none !important;
  }
}
.formcontainer {
  /* height: 100%; */
  border: 1px solid #5082c4;
  width: 40%;
  position: absolute;
  z-index: 10;
  top: 50%;
  right: 50%;
  left: 50%;
  display: flex;
  align-items: "center";
  transform: translate(-50%, -50%);
}
.popcontainer {
  height: 100vh;
  width: 40%;
  position: absolute;
  z-index: 10;
  top: 0%;
  right: 0%;
  /* transform: translate(-50%, -50%); */
  display: flex;
  align-items: "center";
  z-index: 100000000;
}
@media only screen and (max-width: 950px) {
  .formcontainer {
    /* height: 100%; */
    width: 90%;
    border: 1px solid #5082c4;
    position: absolute;
    z-index: 10;
    top: 50%;
    right: 50%;
    display: flex;
    align-items: "center";
    transform: translate(-50%, -50%);
  }
  .popcontainer {
    height: 100vh;
    width: 90%;
    position: absolute;
    z-index: 10;
    top: 0%;
    right: 5%;
    /* transform: translate(-50%, -50%); */
    display: flex;
    align-items: "center";
    z-index: 100000000;
  }
}
