@media screen and (max-width: 766px) {
  .colHeight {
    margin-bottom: 8px;
    height: 50% !important;
  }
}
@media screen and (max-width: 766px) {
  .left {
    display: none !important;
  }
}
p {
  margin-bottom: 0 !important;
}
/* 
.popcontainer {
  width: 40%;
  position: absolute;
  z-index: 10;
  top: 8%;
  right: 30%;
  display: flex;
  align-items: "center";
}

@media only screen and (max-width: 950px) {
  .popcontainer {
    width: 90%;
    position: absolute;
    z-index: 10;
    top: 50%;
    right: 5%;

    display: flex;
    align-items: "center";
  }
} */
.popcontainer {
  height: 100vh;
  width: 40%;
  position: absolute;
  top: 2%;
  right: 0%;
}
.editpopcontainer {
  /* height: 100%; */
  width: 60%;
  position: absolute;
  top: 5%;
  /* // right: 50%; */
  left: 23%;
  transform: translate (-50%, -50%);
  /* background-color: #fff; */
}
@media only screen and (min-width: 1200px) {
  .popcontainer {
    height: 100vh;
    width: 40%;
    position: absolute;
    top: 0%;
    right: 0%;
  }
}

@media only screen and (min-width: 1400px) {
  .popcontainer {
    height: 100vh;
    width: 40%;
    position: absolute;
    top: 0%;
    right: 0%;
  }
}
@media only screen and (max-width: 950px) {
  .popcontainer {
    height: 100vh;
    width: 80%;
    position: absolute;
    z-index: 10;
    top: 0%;
    right: 10%;
    z-index: 2000000000000000000;
  }
  .editpopcontainer {
    /* height: 100%; */
    width: 80%;
    position: absolute;
    top: 5%;
    /* // right: 50%; */
    left: 10%;
    transform: translate (-50%, -50%);
    /* background-color: #fff; */
  }
}
