

@media screen and (max-width:991px) {
    .parent{
        width: 45% !important;
        /* background-color: aquamarine; */
    }
    
}

@media screen and (max-width:766px) {
    .parent{
        width: 25% !important;
        /* background-color: aquamarine; */
    }
    
}

.doughnutchart {
    width: 300px;
    height: 300px;
  }
  
  .chartcontainer {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .chartsegment {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    clip: rect(0, 150px, 300px, 75px); /* Adjust according to desired doughnut size */
    transform-origin: center center;
  }
  
  .chartcenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: bold;
  }
  
