.loading_svg {
    animation: zoomin 1.5s ease-in-out infinite;
    width: 75%;
  }
  
  .loading {
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    width: 100%;
    overflow: hidden;
    position: fixed;
    left: 17%;
    top: 0;
    z-index: 100000000;
    background-color: #eaf0fa;
  }
  @media (max-width: 767.55px){
    .loading {
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    width: 100%;
    overflow: hidden;
    position: fixed;
    left: 0%;
    top: 0;
    z-index: 100000000;
    background-color: #eaf0fa;
  }
  }
  @media (min-width: 768px){
   .loading {
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    width: 100%;
    overflow: hidden;
    position: fixed;
    left: 25%;
    top: 0;
    z-index: 100000000;
    background-color: #eaf0fa;
  }
  }
  @media (min-width: 992px){
   .loading {
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    width: 100%;
    overflow: hidden;
    position: fixed;
    left: 17%;
    top: 0;
    z-index: 100000000;
    background-color: #eaf0fa;
  }
  }