@media only screen and (max-width: 767.95px) {
    .left{
        display: none !important;
    }

}
.popcontainer {
  /* height: 400px; */
  width: 40%;
  position: absolute;
  /* z-index: 10; */
  top: 20%;
  right: 30%;
  /* display: flex;
  align-items: "center"; */
  /* transform: translate(-50%, -50%); */
}
@media only screen and (max-width: 950px) {
  .popcontainer {
    /* height: 400px; */
    width: 90%;
    position: absolute;
    /* z-index: 10; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* display: flex;
    align-items: "center"; */
  }
}
.transform{
    transform: translate(-50%, -50%);
}