.verticalLines {
  width: 2px;
  height: 100%;
  background-color: black;
}

.cardParent {
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: row;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.headingCard {
  font-size: 15px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
}

.rowCard {
  display: flex;
  /* flex-wrap: wrap; */

  flex-direction: row;
  justify-content: space-between;
  width: 25%;
}

@media screen and (max-width: 992px) {
  .table {
    display: none !important;
  }
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

@media screen and (max-width: 767.95px) {
  .left {
    display: none !important;
  }
  .colHeight {
    margin-bottom: 8px;
    /* height: 50% !important; */
  }
  .salesInTopThree {
    display: none !important;
  }
  .customerSatisfication {
    display: none !important;
  }
}

/* small screen customer satisfication and for Sales in top three card */

@media screen and (max-width: 576px) {
  .salesAndCustomerChart {
    /* width: 20% !important; */
    width: 32% !important;
    margin-left: auto;
    margin-right: 2%;
  }
  .salesAndCustomerChartRight {
    margin: 0px !important;
  }
}
