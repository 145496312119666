@media screen and (max-width: 992px) {
  .left {
    display: none !important;
  }
}
.addCatalogContainer {
  /* height: 100%; */
  width: 40%;
  position: absolute;
  bottom: 40%;
  /* // right: 50%; */
  left: 35%;
  transform: translate (-50%, -50%);
  /* background-color: #fff; */
}
@media only screen and (max-width: 950px) {
  .editpopcontainer {
    /* height: 100%; */
    width: 60%;
    position: absolute;
    top: 12%;
    /* // right: 50%; */
    left: 23%;
    transform: translate (-50%, -50%);
    /* background-color: #fff; */
  }
}
