@media screen and (max-width: 576px) {
  .width_xsm_100 {
    width: 100%;
  }
}
@media only screen and (max-width: 767.95px) {
  .left {
    display: none !important;
  }
}
@media screen and (max-width: 992px) {
  .saleInTopThree {
    display: none !important;
  }
}
@media screen and (min-width: 992px) {
  .saleInTopThreeBottom {
    display: none !important;
  }
}
.popcontainer {
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: 10;
  top: 0%;
  /* right: 20%; */
  display: flex;
  align-items: "center";
  justify-content: center;
}

@media only screen and (max-width: 950px) {
  .popcontainer {
    height: 100vh;
    width: 90%;
    position: absolute;
    z-index: 10;
    top: 0%;
    right: 5%;
    /* transform: translate(-50%, -50%); */
    display: flex;
    align-items: "center";
  }
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
