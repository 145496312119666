/* Container for the image with relative positioning */
.image-container {
    position: relative;
    display: inline-block;
}

/* Overlay over the image */
.imageoverlay {
    position: relative;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(234, 67, 53, 0.6);
    /* background-color: rgba(0, 0, 0, 0.5); */
    /* Semi-transparent black color */
    opacity: 0;
    transition: opacity 0.3s ease;
    /* Add a transition effect for smoother visibility */
    border-radius: 25%;
}

/* Show the overlay when hovering the container */
.imagecontainer:hover .imageoverlay {
    opacity: 1;
    border-radius: 25;
}

/* Delete icon inside the overlay */
.deleteicon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 24px;
    cursor: pointer;
}