@media screen and (max-width: 767.95px) {
  .left {
    display: none !important;
  }
}
.catalogHover:hover {
  background-color: rgb(228, 227, 227) !important;
  border-radius: 10px;
  z-index: 30 !important;
  box-shadow: 4px 2px 9px rgba(0, 0, 0, 0.3);
  transform: scale(0.962);
}
.catalogHover {
  /* background-color: #4f81c3 !important; */
  border-radius: 10px;
  z-index: 30 !important;
  /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.3); */
  transform: scale(0.96);
}

.container {
  /* background-color: orange; */
  /* height: 220px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content {
  display: flex;
  flex-direction: column;
}
.bgcolor {
  background-color: #eaf0fa;
}
.popcontainer {
  /* height: 80%; */
  width: 40%;
  position: absolute;
  top: 50%;
  /* // right: "50%", */
  left: 53%;
  transform: translate(-50%, -50%);
  /* background-color: #fff; */
  z-index: 10;
}
.popupcontainer {
  height: 100%;
  width: 60%;
  position: absolute;
  bottom: 0%;
  /* // right: 50%; */
  left: 20%;
  transform: translate (-50%, -50%);
  /* background-color: #fff; */
}
.AddProductCatalogFormPopUp {
  /* height: 100%; */
  width: 40%;
  position: absolute;
  top: 30%;
  /* // right: 50%; */
  left: 35%;
  transform: translate (-50%, -50%);
  /* background-color: #fff; */
}
.AddProductPopUp{
   width: 40%;
  position: absolute;
  top: 30%;
  /* // right: 50%; */
  left: 35%;
  transform: translate (-50%, -50%);
  /* background-color: #fff; */
}
@media only screen and (max-width: 950px) {
  .popcontainer {
    /* height: 80%; */
    width: 80%;
    position: absolute;
    top: 50%;
    /* // right: "50%", */
    left: 50%;
    /* transform: translate(-50%, -50%); */
    /* background-color: #fff; */
    z-index: 10;
  }
  .popupcontainer {
    /* height: 100%; */
    width: 80%;
    position: absolute;
    top: 0%;
    /* // right: 50%; */
    left: 10%;
    /* transform: translate (-50%, -50%); */
    /* background-color: #fff; */
  }
  .AddProductCatalogFormPopUp {
    /* height: 100%; */
    width: 80%;
    position: absolute;
    top: 30%;
    /* // right: 50%; */
    left: 10%;
    transform: translate (-50%, -50%);
    /* background-color: #fff; */
  }
  .AddProductPopUp{
      /* height: 100%; */
    width: 80%;
    position: absolute;
    top: 30%;
    /* // right: 50%; */
    left: 10%;
    transform: translate (-50%, -50%);
    /* background-color: #fff; */
  }
}
:root {
  /*--------------- font_size ---------------*/
  --fs_xsm: 15px;
}
.fs_xsm {
  font-size: var(--fs_xsm);
}

/*--------------- font_weight ---------------*/

/*--------------- font_family ---------------*/

.ff_primary {
  font-family: "InterMedium";
}
.fw_medium {
  font-weight: 500;
}
.fw_semibold {
  font-weight: 600;
}
@media (max-width: 576px) {
  :root {
    /*--------------- font_size ---------------*/
    --fs_xsm: 15px;
  }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
  :root {
    /*--------------- font_size ---------------*/
    --fs_xsm: 12px;
  }
}
@media (min-width: 992px) {
  :root {
    /*--------------- font_size ---------------*/
    --fs_xsm: 15px;
  }
}
@media (min-width: 1200px) {
  :root {
    /*--------------- font_size ---------------*/
    --fs_xsm: 15px;
  }
}
@media (min-width: 1400px) {
  :root {
    /*--------------- font_size ---------------*/
    --fs_xsm: 15px;
  }
}
