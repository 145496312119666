.parent {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.dotContainer {
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    width: 80%;
}


.dot{
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius:50%;
    display: inline-block
}