@media only screen and (max-width: 767.95px) {
    .left{
        display: none !important;
    }

}
.popcontainer {
  /* height: 100vh; */
  width: 40%;
  position: absolute;
  z-index: 10;
  bottom: 0%;
  top: 8%;
  right: 0%;
  display: flex;
  align-items: "center";
}

@media only screen and (max-width: 950px) {
  .popcontainer {
    /* height: 100vh; */
    width: 90%;
    position: absolute;
    z-index: 10;
    top: 5%;
    bottom: 0%;
    right: 5%;
    /* transform: translate(-50%, -50%); */
    display: flex;
    align-items: "center";
  }
}
