
.popcontainer {
  height: 100vh;
  width: 40%;
  position: absolute;
  top: 2%;
  right: 0%;
}
@media only screen and (min-width: 1200px) {
  .popcontainer {
    height: 100vh;
    width: 40%;
    position: absolute;
    top: 0%;
    right: 0%;
  }
}

@media only screen and (min-width: 1400px) {
  .popcontainer {
    height: 100vh;
    width: 40%;
    position: absolute;
    top: 0%;
    right: 0%;
  }
}
@media only screen and (max-width: 950px) {
  .popcontainer {
    height: 100vh;
    width: 80%;
    position: absolute;
    z-index: 10;
    top: 0%;
    right: 10%;
  }
}
