p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
}

.heading {
  font-size: 20px;
}

.subheading {
  font-size: 16px;
  margin-top: 10px;
}

h3 {
  text-align: center;
}

li {
  list-style-type: disc !important;
}

.slip {
  background-color: #3498DB;
  color: white;
}

@media only screen and (max-width: 576px) {
  h3 {
    display: none;
  }

  .slip {
    background-color: #ffc0cc;
    color: black;
  }
}