.popcontainer {
    height: auto;
    width: 40%;
    position: absolute;
    top: 25%;
    left: 30%;
    right: 30%;
    /* display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; */
}

/* @media only screen and (min-width: 1200px) {
    .popcontainer {
        height: 100vh;
        width: 40%;
        position: absolute;
        top: 0%;
        right: 0%;
    }
} */

/* @media only screen and (min-width: 1400px) {
    .popcontainer {
        height: 100vh;
        width: 40%;
        position: absolute;
        top: 0%;
        right: 0%;
    }
} */

/* @media only screen and (max-width: 950px) {
    .popcontainer {
        height: 100vh;
        width: 80%;
        position: absolute;
        z-index: 10;
        top: 0%;
        right: 10%;
        z-index: 2000000000000000000;
    }

} */