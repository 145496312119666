:root {
  /*--------------- font_size ---------------*/

  --fs_lg: 24px;
}
@media (max-width: 576px) {
  :root {
    /*--------------- font_size ---------------*/

    --fs_lg: 18px !important;
  }
}
@media (min-width: 576px) {
  :root {
    /*--------------- font_size ---------------*/

    --fs_lg: 18px !important;
  }
}
@media (min-width: 768px) {
  :root {
    /*--------------- font_size ---------------*/

    --fs_lg: 18px !important;
  }
}
@media (min-width: 992px) {
  :root {
    /*--------------- font_size ---------------*/

    --fs_lg: 18px !important;
  }
}
@media (min-width: 1200px) {
  :root {
    /*--------------- font_size ---------------*/

    --fs_lg: 20px !important;
  }
}
@media (min-width: 1400px) {
  :root {
    /*--------------- font_size ---------------*/

    --fs_lg: 24px !important;
  }
}
@media (min-width: 1600px) {
  :root {
    /*--------------- font_size ---------------*/

    --fs_lg: 24px !important;
  }
}
.fs_lg {
  font-size: var(--fs_lg);
}
