
.popcontainer {
  height: 100vh;
  width: 40%;
  position: absolute;
  /* bottom: 0%; */
  top: 0%;
  right: 0%;
  border: 1px solid rgb(80, 130, 196);
  background: #fff;
}
@media only screen and (max-width: 950px) {
  .popcontainer {
    height: 100vh;
    width: 80%;
    position: absolute;
    /* bottom: 8%; */
    top: 0%;
    right: 10%;
    border: 1px solid rgb(80, 130, 196);
    background: #fff;
  }
}
