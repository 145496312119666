:root {
  /*--------------- font_size ---------------*/
  --fs_xsm: 15px;
}
.fs_xsm {
  font-size: var(--fs_xsm);
}

/*--------------- font_weight ---------------*/

/*--------------- font_family ---------------*/

.ff_primary {
  font-family: "InterMedium";
}
.fw_medium {
  font-weight: 500;
}
.fw_semibold {
  font-weight: 600;
}
@media (max-width: 576px) {
  :root {
    /*--------------- font_size ---------------*/
    --fs_xsm: 12px !important;
  }
  .width_dropdown {
    width: 130px !important;
  }
}
@media (min-width: 576px) {
  .width_dropdown {
    width: 170px !important;
  }
  :root {
    /*--------------- font_size ---------------*/
    --fs_xsm: 12px !important;
  }
  .width_dropdown {
    width: 130px !important;
  }
}
@media only screen and (min-width: 768px) {
  :root {
    /*--------------- font_size ---------------*/
    --fs_xsm: 10px;
  }
  .width_dropdown {
    width: 130px !important;
  }
}
@media (min-width: 992px) {
  :root {
    /*--------------- font_size ---------------*/
    --fs_xsm: 15px !important;
  }
  .width_dropdown {
    width: 150px !important;
  }
}
@media (min-width: 1200px) {
  :root {
    /*--------------- font_size ---------------*/
    --fs_xsm: 15px !important;
  }
  .width_dropdown {
    width: 160px !important;
  }
}
@media (min-width: 1400px) {
  :root {
    /*--------------- font_size ---------------*/
    --fs_xsm: 15px !important;
  }
  .width_dropdown {
    width: 170px !important;
  }
}
.width_dropdown {
  width: 170px;
}
