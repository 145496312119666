.font {
  font-size: 14px;
  margin: 0;
}
@media screen and (max-width: 767.95px) {
  .left {
    display: none !important;
  }
}
.popcontainer {
  /* height: 100%; */
  width: 40%;
  position: absolute;
  top: 13%;
  /* right: 50%; */
  left: 30%;
  background-color: #fff;
}
@media only screen and (max-width: 950px) {
  .popcontainer {
    /* height: 100%; */
    width: 90%;
    position: absolute;
    top: 13%;
    /* right: 50%; */
    left: 5%;
    background-color: #fff;
  }
}
