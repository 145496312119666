.headingCard {
  font-size: 20px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
}

.rowCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 20%;
  border-right: 2px solid black;
}

.cardParent {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
@media screen and (max-width: 992px) {
  .rowCard {
    width: 33.33%;
  
  
  }
}
@media screen and (max-width: 576px) {
  .rowCard {
    width: 100%;
    border-bottom: 2px solid black;
  
    border-right: none;
  }
}
