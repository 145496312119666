
.hoverable{
  margin-right: 15px;
  cursor: pointer;
  /* background-color: #e4eef8; */
}
.activeTab {
  background-color: #e4eef8;;
  border-radius: 10px;
}
.badge {
  background-color:#EC751F;
  color: white;
  padding: 3px 6px;
  border-radius: 50%;
}
@media screen and (max-width:992px) and (min-width:710px) {
  .truncate {
    max-width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
}
@media screen and (max-width:1350px) and (min-width:992px) {
  .truncate {
    max-width: 95px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
}