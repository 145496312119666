.parent {
    width: 80%;
    margin: auto;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%; */
}

.dotContainer {
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    width: 80%;
}


.dot{
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius:50%;
    display: inline-block
}
:root {
  /*--------------- font_size ---------------*/
  --fsSm: 16px;
  --fsXxl: 32px;
}
.fsXxl {
  font-size: var(--fsXxl);
}
.fsSm {
  font-size: var(--fsSm);
}
@media (max-width: 576px) {
  :root {
    /*--------------- font_size ---------------*/
    --fsSm: 16px !important;
    --fsXxl: 21px !important;
  }
}
@media (min-width: 576px) {
  :root {
    /*--------------- font_size ---------------*/
    --fsSm: 14px !important;
    --fsXxl: 19px !important;
  }
}
@media (min-width: 768px) {
  :root {
    /*--------------- font_size ---------------*/
    --fsSm: 14px !important;
    --fsXxl: 20px !important;
  }
}
@media (min-width: 992px) {
  :root {
    /*--------------- font_size ---------------*/
    --fsSm: 25px !important;
    --fsXxl: 35px !important;
  }
}
@media (min-width: 1200px) {
  :root {
    /*--------------- font_size ---------------*/
    --fsSm: 25px !important;
    --fsXxl: 35px !important;
  }
}