@media only screen and (max-width: 767.95px) {
    .left{
        display: none !important;
    }

}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.salesByChannelBottom{
  width: 100%;
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width:1135px) and (min-width:993px){
  .salesByChannelBottom{
    display: block !important;
    padding-left: 2%;
  }
}


/* .tablecontainer {
  max-height: 350px; 
  overflow-y: "auto"
}

.table {
  width: 100%;  
  table-layout: fixed; 
}

.table thead {
  position: sticky;
  top: 0;
  background-color: #fff; 
}

.table tbody {
  display: block;
}

.table tbody td {
  word-wrap: break-word;  
  overflow: hidden; 
  text-overflow: ellipsis;
  white-space: nowrap; 
} */
.tablecontainer {
  max-height: 330px; /* Set the desired maximum height for the container */
  overflow-y: hidden; /* Enable vertical scrolling */
  /* overflow-x: hidden; */
}
.transactionscontainer{
  max-height: 330px; /* Set the desired maximum height for the container */
  overflow-y: hidden; /* Enable vertical scrolling */
  background-color: #fff;
}
.table {
  width: 100%; /* Make the table fill the container width */
  border-collapse: collapse; /* Collapse the table borders */
}

.table thead {
  position: sticky;
  top: 0;
  background-color: #fff; /* Adjust as needed */
  z-index: 1;
}

.table-body {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
 
}

.table tbody tr {
  display: table-row;
}

.table tbody td {
  word-wrap: break-word; /* Allow long cell content to wrap */
  overflow: hidden; /* Hide any overflowing content */
  text-overflow: ellipsis; /* Show ellipsis for truncated content */
  white-space: nowrap; /* Prevent line breaks within cells */
}
/* .navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f1f1f1;
  Add other styles as needed
} */