@media screen and (max-width: 767.95px) {
  .left {
    display: none !important;
  }
}
.popcontainer {
  /* height: 100%; */
  width: 60%;
  position: absolute;
  top: 5%;
  /* // right: 50%; */
  left: 20%;
  transform: translate (-50%, -50%);
  /* background-color: #fff; */
}
@media only screen and (max-width: 950px) {
  .popcontainer {
    /* height: 100%; */
    width: 80%;
    position: absolute;
    top: 5%;
    /* // right: 50%; */
    left: 10%;
    transform: translate (-50%, -50%);
    /* background-color: #fff; */
  }
}
