.container {
  /* background-color: orange; */
  /* height: 220px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.popcontainer {
  height: 100%;
  width: 60%;
  position: absolute;
  bottom: 0%;
  /* // right: 50%; */
  left: 20%;
  transform: translate (-50%, -50%);
  /* background-color: #fff; */
}

@media only screen and (max-width: 950px) {
  .popcontainer {
    height: 100%;
    width: 80%;
    position: absolute;
    bottom: 0%;
    /* // right: 50%; */
    left: 10%;
    transform: translate (-50%, -50%);
    /* background-color: #fff; */
  }
}
