
.hamburger {
    display: none;
  }
  
  .line {
    width: 25px;
    height: 3px;
    background-color: #000;
    margin: 4px;
  }
  
  .menu {
    display: flex;
    list-style-type: none;
  }
  
  .menu li {
    margin: 0 10px;
  }
  
  .menu li a {
    color: #000;
    text-decoration: none;
  }
  
  @media (max-width: 600px) {
    .hamburger {
      display: flex;
      flex-direction: column;
      cursor: pointer;
    }
    
    .menu {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      flex-direction: column;
      background-color: #E9E4E4;
      padding: 10px;
    }
    
    .menu.active {
      display: flex;
    }
    
    .menu li {
      margin: 10px 0;
    }
  }
  .desktopNavbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }
  
  .navbarLeft {
    display: flex;
    align-items: center;
  }
  
  .navbarRight {
    display: flex;
    align-items: center;
  }
  
  .searchIcon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  
  .searchInput {
    width: 200px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
  }
  
  .mobileNavbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }
  
  .hamburgerIcon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .mobileMenu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 10px;
  }
  
  @media (max-width: 768px) {
    .desktopNavbar {
      display: none;
    }
  
    .mobileNavbar {
      display: flex;
    }
  
    .navbarRight {
      display: flex;
      align-items: center;
    }
  
    .searchInput {
      width: 100%;
      margin-right: 10px;
    }
  
    .mobileMenu {
      /* display: flex;
      flex-direction: column;
      align-items: center; */
      margin-top: 140px;
    }
  }


  