@media screen and (max-width: 992px) {
  .border {
    border-right-color: transparent;
    /* border-right-style: solid;  */
    border-right-width: 0px !important;
  }
}
.border {
  border-right-color: #036dcf;
  border-right-style: solid;
  border-right-width: 2px;
}

@media (max-width: 567px) {
  .border {
    border-right-color: transparent;
    /* border-right-style: solid;  */
    border-right-width: 0px !important;
  }
}

@media screen and (min-width: 992px) {
  .saleInTopThreeBottom {
    display: none !important;
  }

  .mediumTable {
    display: none !important;
  }
}

@media screen and (max-width: 992px) {
  .table {
    display: none !important;
  }
}
:root {
  /*--------------- font_size ---------------*/
  --fs_sm: 16px;
  --fs_xxl: 32px;
}
.fs_xxl {
  font-size: var(--fs_xxl);
}
.fs_sm {
  font-size: var(--fs_sm);
}
@media (max-width: 576px) {
  :root {
    /*--------------- font_size ---------------*/
    --fs_sm: 14px !important;
    --fs_xxl: 20px !important;
  }
}
@media (min-width: 576px) {
  :root {
    /*--------------- font_size ---------------*/
    --fs_sm: 14px !important;
    --fs_xxl: 20px !important;
  }
}
@media (min-width: 768px) {
  :root {
    /*--------------- font_size ---------------*/
    --fs_sm: 14px !important;
    --fs_xxl: 20px !important;
  }
}
@media (min-width: 992px) {
  :root {
    /*--------------- font_size ---------------*/
    --fs_sm: 13px !important;
    --fs_xxl: 20px !important;
  }
}
@media (min-width: 1200px) {
  :root {
    /*--------------- font_size ---------------*/
    --fs_sm: 16px !important;
    --fs_xxl: 24px !important;
  }
}
