@media screen and (max-width: 767.95px) {
  .left {
    display: none !important;
  }
}
/* Slider.module.css */
.sliderDots {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 10px !important;
  position: absolute !important;
  bottom: 10px !important;
  width: 100% !important;
  z-index: 1 !important;
}

.sliderDot {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  background-color: #ccc !important;
  margin: 0 5px !important;
  cursor: pointer !important;
}

.sliderDot.slick-active {
  background-color: #555 !important;
}

.sliderArrows {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  margin-top: 20px !important;
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  width: 100% !important;
  z-index: 1 !important;
}

.sliderArrow {
  width: 20px !important;
  height: 20px !important;
  cursor: pointer !important;
}

.sliderArrow img {
  width: 100% !important;
  height: 100% !important;
}
.tablecontainer {
  height: 70%;
  width: 30%;
  position: absolute;
  top: 20%;
  /* // right: 50%; */
  left: 43%;
  transform: translate (-50%, -50%);
  /* background-color: #fff; */
}
@media only screen and (max-width: 992px) {
  .tablecontainer {
    height: 70%;
    width: 80%;
    position: absolute;
    top: 20%;
    /* // right: 50%; */
    left: 10%;
    transform: translate (-50%, -50%);
    /* background-color: #fff; */
  }
}

.popcontainer {
  /* height: 100%; */
  width: 50%;
  position: absolute;
  top: 20%;
  /* // right: 50%; */
  left: 28%;
  /* transform: translate (-50%, -50%); */
  /* background-color: #fff; */
  
}
@media only screen and (max-width: 950px) {
  .popcontainer {
    /* height: 100%; */
    width: 80%;
    position: absolute;
    top: 20%;
    /* // right: 50%; */
    left: 10%;
    /* transform: translate (-50%, -50%); */
    /* background-color: #fff; */
  }
}