/* .tablecontainer {
  max-height: 100px; 
  overflow-y: auto; 
}
.transactionscontainer {
  max-height: 330px; 
  overflow-y: auto; 
}
 */

::-webkit-scrollbar {
  /* width: 2px; */
  opacity: 0;
  transition: opacity 0.3s;
  height: 0px !important;

}

/* Show the scrollbar on hover */
::-webkit-scrollbar:hover {
  opacity: 1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4f81c3;
  /* min-height: 50px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
