.tableContainer {
  overflow-x: auto;
  padding-left: 3.8%;
  /* max-height: "82%"; */
}

.tableContainer table thead {
  position: sticky;
  top: 0;
  background-color: #fff; /* Adjust as needed */
  z-index: 1;
}
/* .tableDataHeight {
  height: 82%;
} */
@media screen and (max-width: 576px) {
  .width {
    width: 59% !important;
  }
}
@media screen and (min-width: 576px) {
  .width {
    width: 65% !important;
  }
}
@media screen and (min-width: 768px) {
  .width {
    width: 55% !important;
  }
}
@media screen and (max-width: 992px) {
  .tableDataHeight {
    height: 72%;
  }
}
@media screen and (min-width: 992px) {
  .width {
    width: 65% !important;
  }
}
.width {
  width: 59%;
}
