.font {
  font-size: 14px;
  margin: 0;
  font-weight: 500;
  padding-top: 3px;
}

.color {
  color: #756060;
  font-size: 14px;
  font-weight: 400;
}

@media only screen and (max-width: 767.95px) {
  .left {
    display: none !important;
  }
}

.bg {
  background-color: red;
  filter: blur(90px);
}

/* .popcontainer {
    height: 90%;
    width: 40%;
    position: absolute;
    top: 7%;
    left: 28%;
} */
.bgBlueColor {
  background-color: #eaf0fa;
  overflow: auto;
  height: 100vh;
}

.sidePadding {
  padding-right: 0px;
}
.popcontainer {
  width: 40%;
  position: absolute;
  z-index: 10;
  top: 1%;
  right: 30%;
  display: flex;
  align-items: "center";
}

@media only screen and (max-width: 950px) {
  .popcontainer {
    width: 90%;
    position: absolute;
    z-index: 10;
    top: 1%;
    right: 5%;
    /* transform: translate(-50%, -50%); */
    display: flex;
    align-items: "center";
  }
}

.infopopcontainer {
  width: 40%;
  position: absolute;
  z-index: 10;
  top: 100%;
  left: -266px;
  display: flex;
  align-items: "center";
}

@media only screen and (max-width: 950px) {
  .infopopcontainer {
    width: 90%;
    position: absolute;
    z-index: 10;
    top: 100%;
    left: -266px;
    /* transform: translate(-50%, -50%); */
    display: flex;
    align-items: "center";
  }
}
@media only screen and (min-width: 1400px){
.popcontainer {
  width: 40%;
  position: absolute;
  z-index: 10;
  top: 2%;
  right: 30%;
  display: flex;
  align-items: "center";
}
}
