* {
  padding: 0;
  margin: 0;
  text-decoration: none !important;
  list-style-type: none !important;
  box-sizing: border-box !important;
}

.card {
  display: flex;
  flex-wrap: wrap;
}

.card-item {
  width: 200px;
  padding: 20px;
  margin: 10px;
  background-color: #f0f0f0;
  border-radius: 8px;
}
.fs_sm {
  font-size: 12px;
}

@media (min-width: 1200px) {
  .col {
    width: 20% !important;
  }
}
@media (max-width: 768px) {
}

.sidenav {
  width: 70%;
  background-color: #f1f1f1;
  height: 100%;
  position: fixed;
  right: -70%;
  top: 0;
  transition: all linear 500ms;
  border-left: 2px solid #4F81C3;
}

.sidenav.open {
  right: 0;
}

/* .toggle-button {
  display: block;
  margin: 10px;
} */

@media (max-width: 768px) {
  /* .sidenav {
    width: 100%;
    height: auto;
    position: static;
    left: 0;
    transition: none;
  } */
}

.fix-screen {
  overflow: hidden;
  height: 100vh;
}
