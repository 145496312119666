.tablecontainer {
    max-height: 300px; /* Set the desired maximum height for the container */
    overflow-y: auto; /* Enable vertical scrolling */
  }
  .table {
    width: 100%; /* Make the table fill the container width */
    border-collapse: collapse; /* Collapse the table borders */
  }
  
  .table thead {
    position: sticky;
    top: 0;
    background-color: #fff; /* Adjust as needed */
    /* z-index: 1; */
  }
  
  .table-body {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow-y: auto;
  }
  
  .table tbody tr {
    display: table-row;
  }
  
  .table tbody td {
    word-wrap: break-word; /* Allow long cell content to wrap */
    overflow: hidden; /* Hide any overflowing content */
    text-overflow: ellipsis; /* Show ellipsis for truncated content */
    white-space: nowrap; /* Prevent line breaks within cells */
  }
.popupcontainer{
     /* height: 100%; */
  width: 40%;
  position: absolute;
  top: 30%;
  /* // right: 50%; */
  left: 35%;
  transform: translate (-50%, -50%);
  /* background-color: #fff; */
}
@media only screen and (max-width: 950px){
 .popupcontainer{
       /* height: 100%; */
    width: 80%;
    position: absolute;
    top: 30%;
    /* // right: 50%; */
    left: 10%;
    transform: translate (-50%, -50%);
    /* background-color: #fff; */
 }   
}