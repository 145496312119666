* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  list-style-type: none;
  text-decoration: none;
}
@media screen and (max-width: 992px) {
  .mt {
    margin-top: 5px;
  }
}
@media only screen and (max-width: 767.95px) {
  .left {
    display: none !important;
  }
}
.viewAndDeleteDiv {
  font-size: 16px;
  font-weight: 500;
  color: #973a3a;
  cursor: pointer;
}
.overflowHidden {
  overflow-x: hidden;
}
